<template>
    <el-divider />
    系统:{{ info.sys }}
    <el-divider />
    web服务器:{{ info.web_server }}



    <div class="demo-date-picker">
    <div class="block">
      <span class="demonstration">选择日期</span>
      <el-date-picker unlink-panels
        v-model="monthdate"
        type="monthrange"
        range-separator="To"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        size="small"
        @change = "changedate"
        value-format="x"
      />
    </div>
</div>
    <el-row :gutter="5">
    <el-col :span="12"> <div id="fish" style="width: 100%; height: 600px;"></div></el-col>
    <el-col :span="12"> <div id="fisher" style="width: 100%; height: 600px;"></div></el-col>
  

  </el-row>

  <el-row>

    <el-col :span="12"> <div id="percent" style="width: 100%; height: 600px;"></div></el-col>
  </el-row>
   

</template>

<script setup>
import { onBeforeMount, reactive, onMounted ,ref} from 'vue'
import { getServerInfo, getFishMonthNum } from '@/api/index.js'
import * as echarts from 'echarts';

const monthdate = ref('')

const changedate = ()=>{
   // console.log(monthdate);
    fishChart(monthdate.value)
}

const info = reactive({
    expire: '',
    sys: '',
    web_server: '',
    user: '',

})

onBeforeMount(() => {
    getServerInfo().then(res => {
        info.expire = res.data.token_expirex
        info.sys = res.data.sys
        info.web_server = res.data.web_server
        info.user = res.data.user
    })
})

onMounted(() => {
    fishChart(0);
});

// 保存配置对象
let chartOptions = {

tooltip: {
    trigger: 'axis',
    axisPointer: {
        type: 'shadow'
    }
},
grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
},
xAxis: {
   
    "axisLable":{
        interval:0,
    },
    data: [],
    axisTick: {
        alignWithLabel: true,
    }
},
yAxis: {
    type: 'value'
},
series: [
    {
        data: [],
        type: 'bar',
        tooltip: {
        valueFormatter: function (value) {
          return value + ' 斤';
        }
      },
    }
]
}

// 保存配置对象
let chartOptions1 = {

tooltip: {
    trigger: 'axis',
    axisPointer: {
        type: 'shadow'
    }
},
legend: {
    data: ['捕获总重量','提成总和']
  },
grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
},
xAxis: {
    type: 'category',
    axisLabel:{
        interval:0,
    },
    data: [],
    axisTick: {
        alignWithLabel: true
    }
},
yAxis: {
    type: 'value'
},
series: [
    {
        name: '捕获总重量',
        data: [],
        type: 'bar',
        tooltip: {
        valueFormatter: function (value) {
          return value + ' 斤';
        }
      },
    },
    {
        name: '提成总和',
        data: [],
        type: 'bar',
        tooltip: {
        valueFormatter: function (value) {
          return value + ' 元';
        }
      },
        
    }
]
}

//百分比饼状图配置

let percent_option = {
  title: {
    text: '品种比例',
    subtext: '',
    left: 'center'
  },
  tooltip: {
    trigger: 'item',
    formatter: "{a} <br/>{b}: {c}斤 ({d}%)"
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [
    {
      name: '水产品比例',
      type: 'pie',
      radius: '50%',
      data: [],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};

async function fishChart(date) {

  
    const res = await getFishMonthNum(date)

    let name = reactive([])
    let value = reactive([])
    let percent_info = reactive([])
    res.data.fish.forEach(element => {
        name.push(element.name)
        value.push(element.num)
    
        percent_info.push({'value':parseFloat(element.num),'name':element.name})
    });

    let fishername = []
    let fishnum = []
    let fishreward = []
    res.data.fisher.forEach(element => {
        fishername.push(element.name)
        fishnum.push(element.num)
        fishreward.push(element.reward)
    });


chartOptions.xAxis.data = name;
chartOptions.series[0].data = value;


chartOptions1.xAxis.data = fishername;
chartOptions1.series[0].data = fishnum;
chartOptions1.series[1].data = fishreward;

percent_option.series[0].data = percent_info





    // 基于准备好的dom，初始化echarts实例
    let fishChat = echarts.init(document.getElementById('fish'));
    let fisherChart = echarts.init(document.getElementById('fisher'));
    let fishepercentChart = echarts.init(document.getElementById('percent'));



    fishChat.setOption(chartOptions);
    fisherChart.setOption(chartOptions1);
    fishepercentChart.setOption(percent_option);

}



</script>

<style scoped>
.chartsstyle {
    width: 500px;
    height: 400px;
}
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

</style>
